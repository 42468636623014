import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import countries from "i18n-iso-countries";

import { getSecretValue } from "../../../lib/secretsManager";
import { currentEnv } from "../../../lib/environment";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

// Custom hooks
import useIsMobile from "../../../hooks/useIsMobile";
import { useReload } from "../../../hooks/useReload";
import useFetch from "../../../hooks/useFetch";

// Template files
import cookies from "js-cookie";

// Reducers
import {
  setCountriesList,
  countryChanged,
  setFormValues,
} from "../../../reducers/ActionFormReducer";
import {
  setDonationBands,
  setTitle,
  setDonationQueryParams,
  currencyHasChanged,
} from "../../../reducers/DonationReducer";
import {
  setQueryParams,
  setIsMobile,
  setFollowUpTemplate,
  setPostActionCopy,
  setPostActionImage,
  resetPostActionCopy,
  resetPostActionImage,
  setTemplateName,
  setForcedScrollToDonate,
  setDonationAskBody,
  setDonationAskTitle,
  setLanguage,
  setPageType,
  setAkPageIds,
} from "../../../reducers/PageReducer";
import {
  getIsMemberPresent,
  getMember,
  setMember,
  setMemberData,
  memberSignedOut,
} from "../../../reducers/MemberReducer";
import { batchActions } from "../../../lib/redux-utils";

import cookieCutter from "cookie-cutter";
import { removeSavedMemberDetails } from "../../../lib/removeSavedMemberDetails";
import { getMemberCurrencyByCountry } from "../../../api";
import TemplateWrapper from "../../../templates/TemplateWrapper";

const logrocketKey = process.env.NEXT_PUBLIC_LOGROCKET_KEY;

function CampaignPage(props) {
  const {
    countriesList,
    image,
    title,
    slug,
    ak_page_ids,
    template_name: templateName,
    campaign_slug,
    notSupportedTemplate,
    donation_bands,
    fundraiserTitle,
    follow_up_template,
    post_action_image: postActionImage,
    post_action_copy: postActionCopy,
    forceScrollToDonate,
    language,
  } = props;

  const { query, isReady } = useRouter();
  const router = useRouter();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { shouldReload } = useReload();

  const isMemberDetailsPresent = useSelector(getIsMemberPresent);
  const memberDetails = useSelector(getMember);
  const isLogRocketEnabled = useFeatureIsOn("enable-logrocket");
  const useAkidEnabled = useFeatureIsOn("enable-akid-search");

  const signedOut = useSelector(memberSignedOut);
  const { akid, currency: currencyFromQueryString } = query;

  const [isFetchFromApi, setFetchFromApi] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [hasAkid, setHasAkid] = useState(false);
  const [isMemberCookiePresent, setIsMemberCookiePresent] = useState(false);

  const { loading, data: memberData } = useFetch(
    `/api/member?id=${akid}`,
    {},
    { depends: [isReady, isFetchFromApi, hasAkid] }
  );

  const {
    ask_button_no: askButtonNo,
    ask_button_yes: askButtonYes,
    ask_message: askMessage,
    ask_title: askTitle,
    donation_ask: donationAsk,
    donation_ask_title: donationAskTitle,
  } = props?.petition || {};
  const templateProps = {
    ...props,
    ...{ postActionAsk: { askButtonNo, askButtonYes, askMessage, askTitle } },
    ...{ loading },
  };

  const getPageType = () => {
    if (templateName.toLowerCase().includes("petition")) {
      return "petition";
    } else if (templateName.toLowerCase().includes("fundraiser")) {
      return "fundraiser";
    } else return "other";
  };

  useEffect(() => {
    if (!isReady) return;

    if (query) {
      dispatch(setQueryParams(query));
      dispatch(setDonationQueryParams(query));
      if (akid && useAkidEnabled) {
        setHasAkid(true);
        setFetchFromApi(true);
      }
    }

    if (memberData && memberData.member !== null && !loading && !signedOut) {
      const {
        first_name = "",
        last_name = "",
        email = "",
        postal = "",
        country = "",
        id = "",
        more = {},
      } = memberData.member || {};
      const { invalidateMemberCookies = false } = memberData || {};
      const phone = more?.action_phone_number || "";
      const member = {
        name: `${first_name} ${last_name}`,
        email,
        postal,
        country,
        id,
        action_phone_number: phone,
      };
      const actions = [];
      if (invalidateMemberCookies) {
        removeSavedMemberDetails(dispatch);
      }
      actions.push(setMember(member));
      actions.push(setFormValues(member));
      actions.push(setMemberData(memberData));
      batchActions(dispatch, actions);
    } else if (
      !hasAkid &&
      !currencyFromQueryString &&
      !isMemberCookiePresent &&
      !isMemberDetailsPresent &&
      !loading
    ) {
      getMemberCurrencyByCountry().then((currencyFromGeo) => {
        const { currency, country } = currencyFromGeo || {
          currency: "USD",
          country: "US",
        };
        dispatch(currencyHasChanged(currency));
        dispatch(countryChanged(country));
      });
    }

    dispatch(setIsMobile(isMobile));

    return () => {};
  }, [
    isReady,
    isMobile,
    query,
    memberData,
    dispatch,
    akid,
    loading,
    signedOut,
    removeSavedMemberDetails,
    currencyFromQueryString,
    isMemberCookiePresent,
  ]);

  useEffect(() => {
    if (isMemberDetailsPresent === false || isMemberCookiePresent === true) {
      setFetchFromApi(true);
    } else {
      const actions = [];
      if (memberDetails?.country !== null) {
        actions.push(countryChanged(memberDetails?.country));
      }
      batchActions(dispatch, actions);
    }
  }, [isMemberDetailsPresent, memberDetails, dispatch, isMemberCookiePresent]);

  useEffect(() => {
    let isMounted = true;
    if (!isReady) return;
    if (isMounted && !akid) {
      const memberCookie = cookieCutter.get("member_id");
      if (memberCookie?.length > 0) {
        setIsMemberCookiePresent(true);
        setHasAkid(true);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [setIsMemberCookiePresent, isReady, akid]);

  useEffect(() => {
    let actions = [];

    actions.push(setAkPageIds(ak_page_ids));

    if (donation_bands) {
      actions.push(setDonationBands(donation_bands));
    }

    if (countriesList) {
      actions.push(setCountriesList(countriesList));
    }

    if (follow_up_template) {
      actions.push(setFollowUpTemplate(follow_up_template));
    }

    if (fundraiserTitle) {
      actions.push(setTitle(fundraiserTitle));
    }

    if (postActionCopy) {
      actions.push(setPostActionCopy(postActionCopy));
    } else {
      actions.push(resetPostActionCopy());
    }

    if (postActionImage) {
      actions.push(setPostActionImage(postActionImage));
    } else {
      actions.push(resetPostActionImage());
    }

    if (templateName) {
      actions.push(setTemplateName(templateName));
      actions.push(setPageType(getPageType()));
    }

    if (forceScrollToDonate) {
      actions.push(setForcedScrollToDonate(forceScrollToDonate));
    }

    if (language) {
      actions.push(setLanguage(language));
    }

    if (donationAsk) {
      actions.push(setDonationAskBody(donationAsk));
    }

    if (donationAskTitle) {
      actions.push(setDonationAskTitle(donationAskTitle));
    }

    if (actions.length > 0) batchActions(dispatch, actions);

    return () => {
      actions = [];
    };
  }, [
    countriesList,
    follow_up_template,
    donation_bands,
    fundraiserTitle,
    dispatch,
    donationAsk,
    donationAskTitle,
    forceScrollToDonate,
    postActionCopy,
    postActionImage,
    templateName,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && shouldReload) {
      console.log("BuildId mismatch, triggering page reload...");
      document.location.reload();
    }

    return () => {
      isMounted = false;
    };
  }, [shouldReload]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && isReady && notSupportedTemplate && redirected === false) {
      setRedirected(true);
      const champaignUrl = `${process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST}/a/${campaign_slug}${window?.location?.search}`;
      router.push(champaignUrl);
    }

    return () => {
      isMounted = false;
    };
  }, [
    isReady,
    notSupportedTemplate,
    redirected,
    setRedirected,
    router,
    campaign_slug,
  ]);

  if (isLogRocketEnabled && logrocketKey) {
    if (templateName && templateName.toLowerCase().includes("fundraiser")) {
      const id = cookies.get("pronto_id") || memberDetails?.id;
      LogRocket.init(logrocketKey);
      LogRocket.identify(id, {
        email: memberDetails?.email,
      });
    }
  }

  return (
    <>
      {notSupportedTemplate === false && (
        <>
          <TemplateWrapper
            templateName={templateName}
            templateProps={templateProps}
            forceScrollToDonate={forceScrollToDonate}
          />
        </>
      )}
    </>
  );
}

// This function gets called at build time
export async function getStaticPaths() {
  return { paths: [], fallback: "blocking" };
}

export async function getStaticProps(props) {
  const { params } = props;
  const { campaign_slug } = params;
  const url = `${process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST}/api/pages/${campaign_slug}.json`;
  const cypressTestSlug = "this-page-does-not-exist";
  const supportedTemplates = [
    "Default: Petition And Scroll To Share Greenpeace",
    "Default: Petition And Scroll To Donate Greenpeace",
    "Fundraiser With Title Below Image",
  ];

  const tempSupportedSlugs = [
    "un-membre-existant-signe-une-petition-via-un-mailing",
    "bestehende-mitglieder-unterzeichnen-eine-petition-per-mailing",
    "miembro-existente-firma-una-peticion-a-traves-de-un-correo",
  ];
  let notSupportedTemplate = false;

  const { siteKey } = (await getSecretValue(
    `${currentEnv}/recaptchaThree`
  )) || { siteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY };

  try {
    const resp = await fetch(url);
    const data = await resp.json();

    if (resp.status == 404 || !data) {
      if (campaign_slug !== cypressTestSlug) {
        console.warn("Champaign API call failed", url);
        console.warn(`Page not found ${campaign_slug}`, resp.status);
      }

      return {
        redirect: {
          destination: "https://www.eko.org",
          permanent: false,
        },
      };
    }

    // If the campaign has a petition or fundraiser component & doesnt use a supported template
    // then redirect to champaign
    const {
      template_name: templateName,
      petition,
      fundraiser,
      publish_status,
      language,
    } = data || {};

    if (
      (petition || fundraiser) &&
      !supportedTemplates.includes(templateName)
    ) {
      notSupportedTemplate = true;
    }

    if (
      tempSupportedSlugs.includes(campaign_slug) ||
      campaign_slug.indexOf("pronto-bug-bash") > -1
    ) {
      notSupportedTemplate = false;
    }

    if (publish_status === "unpublished") {
      return {
        redirect: {
          destination: `/page/${campaign_slug}`,
          permanent: false,
        },
      };
    }

    countries.registerLocale(
      require(`i18n-iso-countries/langs/${language}.json`)
    );

    const countriesList = countries.getNames(language, { select: "official" });
    // Temp fix as ISO-3166 has it as province of china, but we want it as Taiwan
    // https://www.iso.org/obp/ui/#iso:code:3166:TW
    countriesList["TW"] = countries.getName("TW", language, {
      select: "alias",
    });
    const { donation_bands = null, title: fundraiserTitle = "" } =
      data.fundraiser || {};

    return {
      props: {
        ...data,
        countriesList,
        donation_bands,
        ...(fundraiserTitle && { fundraiserTitle }),
        messages: (await import(`../../../messages/${language}.json`)).default,
        dir: language == "ar" ? "rtl" : "ltr",
        notSupportedTemplate,
        campaign_slug,
        recaptchaSiteKey: siteKey,
        forceScrollToDonate: true,
        language,
      },
      revalidate: 1800, // 30 minutes
    };
  } catch (e) {
    console.log(
      `Error on getStaticProps while trying to render page with slug: ${campaign_slug}`,
      e
    );
    return { notFound: true };
  }
}

export default CampaignPage;
