import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CookiePreferences from "../components/common/CookiePreferences";
// Internal Components
import { importRetry } from "../components/Utils";
const TrackingScripts = dynamic(() =>
  importRetry(() => import("../components/TrackingScripts"))
);
import FullPageSpinner from "../components/common/FullPageSpinner";

// Template files
import Petition from "./Petition";
import Fundraiser from "./Fundraiser";

import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react";

function TemplateWrapper({ templateName, templateProps, forceScrollToDonate }) {
  const router = useRouter();
  const { isReady, push } = router;
  const { ready } = useGrowthBook();
  const { campaign_slug } = templateProps;
  const [loading, setLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const goFundMeOn = useFeatureIsOn("go-fund-me");
  const amnestyOn = useFeatureIsOn("amnesty-layout");

  useEffect(() => {
    if (templateName.toLowerCase().includes("fundraiser")) {
      if (ready && isReady) {
        if (goFundMeOn || amnestyOn) {
          // If the feature is on, prepare for redirection
          setShouldRedirect(true);
        }
        // Delay setting loading to false to provide a buffer for redirection and avoid flickering
        setTimeout(() => {
          setLoading(false);
        }, 350);
      }
    } else {
      setLoading(false);
    }
  }, [
    ready,
    templateName,
    goFundMeOn,
    amnestyOn,
    shouldRedirect,
    campaign_slug,
    isReady,
  ]);

  // Redirect logic
  if (shouldRedirect) {
    // Perform redirection, e.g., push("fund/slug");
    const segment = goFundMeOn ? "fund" : amnestyOn ? "gift" : "a";
    const newFormUrl = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/${segment}/${campaign_slug}${window?.location?.search}`;
    console.log("Redirecting to", newFormUrl);
    push(newFormUrl);
    return <FullPageSpinner />; // Prevent rendering during redirection
  }

  // Loading state
  if (loading || !ready || shouldRedirect) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Header />
      {templateName.toLowerCase().includes("petition") && (
        <Petition {...templateProps} scrollToDonate={forceScrollToDonate} />
      )}
      {templateName.toLowerCase().includes("fundraiser") && (
        <Fundraiser {...templateProps} />
      )}
      <Footer />
      <CookiePreferences />
      <TrackingScripts />
    </>
  );
}

export default TemplateWrapper;
